import React, {useEffect} from "react"
import { graphql, Link } from "gatsby"
import SEO from "../components/SEO"
import Logo from "../components/logo"
import Nav from "../components/nav"

export default function Home({ data }) {
  const collection = data.allAirtable.nodes

  useEffect(() => {
    const nextButton = document.querySelector("aside .right-0")
    const prevButton = document.querySelector("aside .left-0")

    const keyBoardNavigation = (e) => {
      if(e.code === "ArrowRight" && nextButton) {
        nextButton.click()
      } else if(e.code === "ArrowLeft" && prevButton) {
        prevButton.click()
      }
    }

    window.addEventListener("keyup", keyBoardNavigation, {
      capture: true,
      once: true
    })
  }, [])
  
  return (
    <>
      <SEO />
      <Logo />
      <Nav />  
      <main className="entry">
        <h2 dangerouslySetInnerHTML={{
          __html: collection[0].data.Content,
        }} />
        <time className="time tracking-wide row-start-1" dateTime={collection[0].data.Date}>
          {collection[0].data.Date.replace(/-/g,"/")}
        </time>
        <aside>
          <Link className="grid items-center absolute bottom-10 sm:inset-y-1/2 left-0 sm:transform sm:-translate-y-1/2 h-20 w-10 sm:h-28 sm:w-14" to={`/${collection[1].data.Date.replace(/-/g,"/")}`}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
            <span className="sr-only">{collection[1].data.Date.replace(/-/g,"/")}</span>
          </Link>

          <div class="absolute bottom-3 left-1/2 transform -translate-x-1/2 text-sm flex gap-3 whitespace-no-wrap">
            <a href={`https://www.addtoany.com/share#url=https://axiomsandspurs.com/` + collection[0].data.Date.replace(/-/g,"/") + `&amp;title=` + collection[0].data.Content} target="_blank">Share</a> <span>/</span> <Link to="/about">About</Link>
          </div>
        </aside>
      </main>
    </>
  )
}

export const mostRecentAP = graphql`
  query queryList {
    allAirtable(limit: 2, sort: {fields: data___Date, order: DESC}) {
      nodes {
        data {
          Content
          Date
        }
      }
    }
  }
`
