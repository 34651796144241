import React from "react"
import { Link } from "gatsby"

function Nav() {
  return (<></>)

  // return (
  //   <nav className="
  //     grid place-items-center fixed top-2 right-2 pt-1 pb-1 pl-1 pr-7 bg-white p-1 max-w-0 whitespace-nowrap
  //     transition-all duration-1000
  //     sm:top-5 sm:right-5
  //     hover:max-w-7xl">
  //     <a href="#navigation" className="block absolute right-0 w-8 h-8 transition-all duration-75 text-black">
  //       <svg className="transition-all duration-75" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  //         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
  //       </svg>
  //     </a>
  //     <ul id="navigation" tabIndex="0" className="
  //       outline-none
  //       grid grid-flow-col gap-3 justify-self-end
  //       hover:ml-2 hover:mr-4
  //     ">
  //       <li>
  //         <Link to={`/`} className="hover:underline">Daily Journal</Link>
  //       </li>
  //       <li>
  //         <Link to={`/about`}>Statement of Purpose</Link>
  //       </li>
  //     </ul>
  //   </nav>
  // )
}

export default Nav